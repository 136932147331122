import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import { navigate } from "gatsby";
import React, { useState } from "react";
import {
  CAlert,
  CFormControl,
  CLink,
  CTextField,
  SubmitButton
} from "../utils/globalStyles";
import Layout from "../components/layout";
import { sendVerifyEmail, signUp, user } from "../utils/auth";
import { NavigatedToState } from "../types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

type Props = NavigatedToState;
const SignUp: React.FC<Props> = ({ location }) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState(
    typeof window !== "undefined" ? user.getDisplayName() : ""
  );
  return (
    <Layout>
      <Container maxWidth="sm">
        <>
          <Typography variant="h4">Sign Up</Typography>
          <form
            onSubmit={async e => {
              e.preventDefault();
              if (typeof window === "undefined") return;
              setError("");
              try {
                await signUp.withPassword(email, password);
                await user.setDisplayName(displayName);
                await sendVerifyEmail();
                location?.state?.navigatedTo
                  ? navigate(-1)
                  : navigate("/profile");
              } catch (e) {
                setError(e.message);
                console.log(e);
              }
            }}
          >
            <CFormControl fullWidth>
              <CTextField
                label="Display Name"
                variant="filled"
                value={displayName}
                required
                type="text"
                onChange={e => setDisplayName(e.target.value)}
              />
            </CFormControl>
            <CFormControl fullWidth>
              <CTextField
                label="Email"
                variant="filled"
                value={email}
                required
                type="email"
                onChange={e => setEmail(e.target.value)}
              />
            </CFormControl>

            <CFormControl fullWidth>
              <CTextField
                label="Password"
                required
                variant="filled"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </CFormControl>
            <CFormControl>
              <FormControlLabel
                control={<Checkbox required />}
                label={
                  <>
                    I accept the <CLink to="/privacy-policy">privacy policy</CLink>{" "} and the <CLink to="/cookie-policy">Cookie Policy</CLink>{" "}
                    of this website.
                  </>
                }
              />
            </CFormControl>
            <Typography>
              Already have an account?{" "}
              <CLink to="/sign-in" navigatedUntrue={true}>
                Sign in
              </CLink>
            </Typography>

            <SubmitButton color="primary" variant="contained" type="submit">
              Sign Up
            </SubmitButton>
            {error ? (
              <CAlert severity="error" variant="outlined">
                {error}
              </CAlert>
            ) : null}
          </form>
        </>
      </Container>
    </Layout>
  );
};

export default SignUp;
